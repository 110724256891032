import domReady from '@roots/sage/client/dom-ready';
import $ from 'jquery';
/**
 * Application entrypoint
 */
domReady(async () => {
  $(".hero-section__down").click(function() {
      $('html, body').animate({
          scrollTop: $(".intro-section").offset().top
      }, 2000);
  });
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);
